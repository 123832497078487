// export const API_URL = 'https://stt-user-api.acuizen.com';
// export const USER_API_URL = 'https://stt-user-api.acuizen.com';
export const MAIN_API_URL = 'https://dev.stt.acuizen.com';

export const API_URL = 'https://dev.stt-user.acuizen.com';
export const USER_API_URL = 'https://dev.stt-user.acuizen.com';
// export const MAIN_API_URL = 'https://dev.stt.acuizen.com';

// export const API_URL = 'http://localhost:3060';
// export const USER_API_URL = 'http://localhost:3060';
// export const MAIN_API_URL = 'http://localhost:3010';
export const EXTERNAL_USERS_URL = MAIN_API_URL + '/users/external';
export const INTERNAL_USERS_URL = MAIN_API_URL + '/internal/users/beta';
export const THAILAND_USERS_URL = MAIN_API_URL + '/thailand/users';
export const KOREA_USERS_URL = MAIN_API_URL + '/korea/users';
export const INDIA_USERS_URL = MAIN_API_URL + '/india/users';
export const UK_USERS_URL = MAIN_API_URL + '/uk/users';
export const PHILLIPINES_USERS_URL = MAIN_API_URL + '/phillipines/users';

export const GROUP_EHS_ROLE_URL = MAIN_API_URL + '/group-ehs-roles';
export const GET_MY_USER_LOCATION_ROLE_URL = (id) => { return MAIN_API_URL + '/my-user-location-roles/' + id };
export const GROUP_EHS_ROLE_WITH_ID_URL = (id) => {
    return MAIN_API_URL + '/group-ehs-roles/' + id;
}

export const EPTW_CONTROL_MEASURE_ID = (id) => {
    return API_URL + '/report-incidents-create-control-measures/' + id
}
export const EPTW_CHECKLIST = API_URL + '/eptw-checklists';

export const USER_LOCATION_ROLE_WITH_ID_URL = (id) => {
    return MAIN_API_URL + '/user-location-roles/' + id;
}
export const EXTERNAL_USER_PASSWORD_RESET = MAIN_API_URL + '/users/external/reset-password';
export const EXTERNAL_USER_MFA_RESET = MAIN_API_URL + '/users/external/reset-mfa';
export const EXTERNAL_USER_DELETE = MAIN_API_URL + '/users/external/delete';

export const REPORT_ROLE_URL = MAIN_API_URL + '/report-roles';
export const GET_INDIVIDUAL_USER_LOCATION_ROLE_URL = MAIN_API_URL + '/user-location-roles/get-individual-users';
export const INDIVIDUAL_USER_LOCATION_ROLE_URL = MAIN_API_URL + '/individual-user-location-roles';

export const CURRENT_USER_URL = API_URL + '/users/me';
export const GET_USERS_BY_ROLE = API_URL + '/users/get_users';

export const INCIDENT_REVIWERER_URL = MAIN_API_URL + '/users/incident-reviewer';
export const INCIDENT_OWNER_LIST_URL = API_URL + '/incident-owner-list';
export const SERVICE_NOW_TOKEN_URL = API_URL + '/oauth-token';
export const SERVICE_NOW_BUG_URL = API_URL + '/reportBug'
export const SERVICE_NOW_UPLOAD = API_URL + '/uploadToServiceNow';
export const ALL_LOCATION1_URL = API_URL + '/all-location-ones';
export const LOCATION1_URL = API_URL + '/location-ones';
export const LOCATION2_URL = API_URL + '/location-twos';
export const LOCATION3_URL = API_URL + '/location-threes';
export const LOCATION4_URL = API_URL + '/location-fours';
export const LOCATIONS_URL = API_URL + '/location-ones';
export const SUBMIT_URL = API_URL + '/location-ones';
export const CHECKLIST_URL = API_URL + '/checklists';
export const INSPECTION_URL = API_URL + '/inspections';
export const EQUIPMENT_URL = API_URL + '/equipment';
export const AUDIT_URL = API_URL + '/audits';
export const MY_AUDIT_URL = API_URL + '/my-audits';
export const AUDIT_LIST = API_URL + '/all-audits';
export const DOCUMENTS_URL = API_URL + '/location-ones';
export const LIGHTING_URL = API_URL + '/lightings';
export const WEATHER_CONDITION_URL = API_URL + '/weather-conditions';
export const LOCATION_CONFIG_URL = API_URL + '/location-ones';
export const STATIC_URL = USER_API_URL + '/docs';
export const USERS_URL = API_URL + '/users';

export const INSPECTOR_USERS_URL = API_URL + '/users/inspector';
export const DYNAMIC_TITLES_URL = API_URL + '/dynamic-titles';
export const OBSERVATION_REPORT_URL = USER_API_URL + '/observation-reports';
export const REPORT_INCIDENT_URL = USER_API_URL + '/report-incidents';
export const REPORT_INCIDENT_REVIEW_URL = USER_API_URL + '/report-incidents-review';
export const GET_INCIDENT_OWNER_INCIDENT = USER_API_URL + '/get-report-incidents-incident-owner';
export const GET_REVIEWER_INCIDENT = USER_API_URL + '/get-report-incidents-reviewer';
export const GET_REPORTER_INCIDENT = USER_API_URL + '/get-report-incidents-reporter';
export const REPORT_INCIDENT_INVESTIGATE_URL = USER_API_URL + '/report-incidents-investigate';
export const REPORT_INCIDENT_LEAD_INVESTIGATOR_URL = USER_API_URL + '/report-incidents-lead-investigate';
export const REPORT_INCIDENT_TRIGGER_URL = USER_API_URL + '/report-incidents-trigger';
export const LEAD_INVESTIGATOR_URL = USER_API_URL + '/lead-investigator-list';
export const ACTION_URL = API_URL + '/actions';
export const FILE_URL = API_URL + '/files';
export const OBSERVATION_REPORT_BY_OTHERS_URL = API_URL + '/observation-reports-by-locations';
export const ALL_USERS_BY_LOCATION = API_URL + '/all-users-by-location';

export const ALL_INCIDENT_URL = API_URL + '/all-report-incidents';
export const ALL_REPORT_DATA_URL = API_URL + '/report-data';
export const My_REPORT_DATA_URL = API_URL + '/my-report-data';
export const ALL_PERMITS_URL = API_URL + '/all-permit-reports';

export const OBSERVATION_REVIEWER_LIST_URL = API_URL + '/action-reviewer-list';
export const OBSERVATION_ASSIGNEE_LIST_URL = API_URL + '/action-assignee-list';

export const HUMAN_ONE_URL = API_URL + '/human-ones';
export const INSPECTION_ACTION_PLAN_REVIEWER = API_URL + '/users/inspection_action_plan_implementor';
export const AUDIT_ACTION_PLAN_REVIEWER = API_URL + '/users/audit_action_plan_reviewer';


export const OBS_REPORT_PROCESS = API_URL + '/observations/process';
export const OBS_CATEGORY_STATS = API_URL + '/observations/category-statistics';
export const OBS_TYPE_COND_STATS = API_URL + '/observations/type-condition-statistics';
export const OBS_TYPE_COND_MONTH = API_URL + '/observations/type-condition-statistics-monthly';
export const OBS_TOP_GHS_TYPE = API_URL + '/observations/top-ghs-type-condition';
export const OBS_TOP_GHS_MONTH = API_URL + '/observations/top-ghs-monthly';
export const OBS_CATEGORY_MONTH = API_URL + '/observations/category-monthly';
export const OBS_TOP_REPORTERS = API_URL + '/observations/top-reporters';



export const LOCATION_ONE_WITH_ID_URL = (id) => {
    return API_URL + '/location-ones/' + id;
}

export const LOCATION_TWO_WITH_ID_URL = (id) => {
    return API_URL + '/location-twos/' + id;
}

export const LOCATION_THREE_WITH_ID_URL = (id) => {
    return API_URL + '/location-threes/' + id;
}

export const LOCATION_FOUR_WITH_ID_URL = (id) => {
    return API_URL + '/location-fours/' + id;
}

export const HUMAN_ONE_WITH_ID_URL = (id) => {
    return API_URL + '/human-ones/' + id;
}


export const HUMAN_TWO_URL = API_URL + '/human-twos';

export const HUMAN_TWO_WITH_ID_URL = (id) => {
    return API_URL + '/human-twos/' + id;
}


export const HUMAN_ONE_TWO_URL = (id) => {
    return API_URL + '/human-ones/' + id + '/human-twos';
}


export const EDIT_HUMAN_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/human-ones/' + id;
            break;
        case 'tier2':

            return API_URL + '/human-twos/' + id;
            break;


        default: break;
    }
}

export const AUDIT_FINDINGS_URL = API_URL + '/audit-findings';
export const AUDIT_FINDINGS_WITH_ID_URL = (id) => {
    return API_URL + '/audit-findings/' + id;
}
export const AUDIT_FINDINGS_ASSIGN_ACTION_URL = (id) => { return API_URL + '/audit-findings-assign-action/' + id };
export const AUDIT_FINDINGS_ACTION_URL = (id) => { return API_URL + '/audit-findings/action/' + id };
export const INSPECTION_ACTION_URL = (id) => { return API_URL + '/inspections/actions/' + id };
export const AUDIT_ACTION_URL = (id) => { return API_URL + '/audits/actions/' + id };

export const AUDIT_GMS1_URL = API_URL + '/audit-gms-ones';


export const AUDIT_WITH_ID_URL = (id) => {
    return API_URL + '/audits/' + id;
}


export const AUDIT_GMS1_WITH_ID_URL = (id) => {
    return API_URL + '/audit-gms-ones/' + id;
}


export const AUDIT_GMS2_URL = API_URL + '/audit-gms-twos';

export const AUDIT_GMS2_WITH_ID_URL = (id) => {
    return API_URL + '/audit-gms-twos/' + id;
}


export const AUDIT_GMS3_URL = API_URL + '/audit-gms-threes';

export const AUDIT_GMS3_WITH_ID_URL = (id) => {
    return API_URL + '/audit-gms-threes/' + id;
}


export const AUDIT_GMS1_GMS2_URL = (id) => {
    return API_URL + '/audit-gms-ones/' + id + '/audit-gms-twos';
}


export const AUDIT_GMS2_GMS3_URL = (id) => {
    return API_URL + '/audit-gms-twos/' + id + '/audit-gms-threes';
}

export const EDIT_AUDIT_GMS_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/audit-gms-ones/' + id;
            break;
        case 'tier2':

            return API_URL + '/audit-gms-twos/' + id;
            break;

        case 'tier3':

            return API_URL + '/audit-gms-threes/' + id;
            break;

        default: break;
    }
}

export const EQUIPMENT_WITH_ID_URL = (id) => {
    return API_URL + '/equipment/' + id;
}


export const OBSERVATION_REVIEWER_SUBMIT_URL = (id) => {
    return API_URL + '/actions/' + id;
}
export const OBSERVATION_APPROVE_URL = (id) => {
    return API_URL + '/actions/' + id;
}
export const OBSERVATION_REJECT_LIST_URL = (id) => {
    return API_URL + '/actions/' + id;
}


export const PERMIT_REPORTS = API_URL + '/permit-reports';
export const PERMIT_REPORT_WITH_ID = (id) => {
    return API_URL + '/permit-reports/' + id;
}

export const PERMIT_ACTION_REPORT_WITH_ID = (id, id1) => {
    return API_URL + '/permit-reports/' + id + '/' + id1;
}

export const PERMIT_ACTION_REPORT_REJECT_WITH_ID = (id, id1) => {
    return API_URL + '/permit-reports-reject/' + id + '/' + id1;
}
export const OBSERVATION_REPORT_WITH_ID = (id) => {
    return API_URL + '/observation-reports/' + id;
}


export const REPORT_INCIDENT_ACTIONS_WITH_ID = (id) => {
    return API_URL + '/report-incident/actions/' + id;
}



export const REPORT_INCIDENT_URL_WITH_ID = (id) => {
    return API_URL + '/report-incidents/' + id;
}


export const CLOSEOUT_PERMIT = (id) => {
    return API_URL + '/permit-reports-close-out/' + id
}

export const NORMALIZE_PERMIT = (id) => {
    return API_URL + '/permit-reports-normalization/' + id
}

export const WITHDRAW_PERMIT = (id) => {
    return API_URL + '/permit-reports-withdraw/' + id
}

export const SUSPEND_PERMIT = (id) => {
    return API_URL + '/permit-reports-suspend/' + id
}

export const SAVE_REPORT_INCIDENT_URL_WITH_ID = (id) => {
    return API_URL + '/save-report-incidents/' + id;
}

export const RETURN_REPORT_INCIDENT_URL_WITH_ID = (id) => {
    return API_URL + '/return-report-incidents/' + id;
}

export const RESUBMIT_REPORT_INCIDENT_URL_WITH_ID = (id) => {
    return API_URL + '/resubmit-report-incidents/' + id;
}

export const REPORT_INCIDENT_REVIEW_URL_WITH_ID = (id) => {
    return API_URL + '/report-incidents-review/' + id;
}

export const REPORT_INCIDENT_INVESTIGATE_URL_WITH_ID = (id) => {
    return API_URL + '/report-incidents-investigate/' + id;
}
export const LOCATION_TWO = (id) => {
    return API_URL + '/location-ones/' + id + '/location-twos';
}
export const LOCATION_THREE = (id) => {
    return API_URL + '/location-twos/' + id + '/location-threes';
}
export const LOCATION_FOUR = (id) => {
    return API_URL + '/location-threes/' + id + '/location-fours';
}
export const LOCATION_FIVE = (id) => {
    return API_URL + '/location-fours/' + id + '/location-fives';
}
export const LOCATION_SIX = (id) => {
    return API_URL + '/location-fives/' + id + '/location-sixes';
}
export const PERMIT_REPORT = API_URL + '/permit-reports'
export const DCSO_APPROVER_LIST = API_URL + '/users/eptw-dcso-approver'
export const DCSO_HIGN_RISK_ASSESSOR = API_URL + '/eptw-construction-assessor'

export const DCSO_HIGH_RISK_APPROVER = API_URL + '/users/eptw-high-risk-approver';

export const REPORT_INCIDENT_INVESTIGATION_URL_WITH_ID = (id) => {
    return API_URL + '/report-incidents-investigation/' + id;
}

export const REPORT_INCIDENT_INVESTIGATION_SAVE_URL_WITH_ID = (id) => {
    return API_URL + '/save-report-incidents-investigation/' + id;
}

export const REPORT_INCIDENT_INVESTIGATION_VERIFY_URL_WITH_ID = (id, action_id) => {
    return API_URL + '/report-incidents-investigation-verify/' + id + '/' + action_id;
}


export const INSPECTIONS_ACTIONS_SUBMIT_WITH_ID = (id, action_id) => {
    return API_URL + '/inspections/' + id + '/' + action_id;
}

export const REPORT_INCIDENT_INVESTIGATION_REINVESTIGATE_URL_WITH_ID = (id, action_id) => {
    return API_URL + '/report-incidents-investigation-reinvestigate/' + id + '/' + action_id;
}


export const REPORT_INCIDENT_INVESTIGATE_CLOSE_URL_WITH_ID = (id) => {
    return API_URL + '/report-incidents-investigate-close/' + id;
}

export const DYNAMIC_TITLES_WITH_ID = (id) => {
    return API_URL + '/dynamic-titles/' + id;
}

export const USERS_URL_WITH_ID = (id) => {
    return API_URL + '/users/' + id;
}

export const LOCATION1_WITH_ID_URL = (id) => {
    return API_URL + '/location-ones/' + id;
}

export const GMS1_URL = API_URL + '/ghs-ones';

export const GMS1_WITH_ID_URL = (id) => {
    return API_URL + '/ghs-ones/' + id;
}


export const WORK_ACTIVITIES_URL = API_URL + '/work-activities';

export const WORK_ACTIVITIES_WITH_ID_URL = (id) => {
    return API_URL + '/work-activities/' + id;
}


export const RISK_CATEGORIES_URL = API_URL + '/risk-categories';

export const RISK_CATEGORIES_WITH_ID_URL = (id) => {
    return API_URL + '/risk-categories/' + id;
}

export const SURFACE_TYPES_URL = API_URL + '/surface-types';

export const SURFACE_TYPES_WITH_ID_URL = (id) => {
    return API_URL + '/surface-types/' + id;
}

export const SURFACE_CONDITIONS_URL = API_URL + '/surface-conditions';

export const SURFACE_CONDITIONS_WITH_ID_URL = (id) => {
    return API_URL + '/surface-conditions/' + id;
}


export const EHS_ROLE_URL = API_URL + '/ehs-roles';

export const EHS_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/ehs-roles/' + id;
}


export const EPTW_ROLE_URL = API_URL + '/eptw-roles';

export const EPTW_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/eptw-roles/' + id;
}

export const INCIDENT_ROLE_URL = API_URL + '/incident-roles';

export const INCIDENT_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/incident-roles/' + id;
}

export const INSPECTION_ROLE_URL = API_URL + '/inspection-roles';

export const INSPECTION_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/inspection-roles/' + id;
}

export const PLANT_ROLE_URL = API_URL + '/plant-roles';

export const PLANT_ROLE_WITH_ID_URL = (id) => {
    return API_URL + '/plant-roles/' + id;
}


export const GMS1_GMS2_URL = (id) => {
    return API_URL + '/ghs-ones/' + id + '/ghs-twos';
}

export const INCIDENT_CATEGORY_URL = API_URL + '/incident-circumstance-categories';

export const INCIDENT_CATEGORY_TYPE_URL = (id) => {
    return API_URL + '/incident-circumstance-categories/' + id + '/incident-circumstance-types';
}



export const INCIDENT_TYPE_DESCRIPTION_URL = (id) => {
    return API_URL + '/incident-circumstance-types/' + id + '/incident-circumstance-descriptions';
}


export const GMS2_WITH_ID_URL = (id) => {
    return API_URL + '/ghs-twos/' + id;
}


export const LOCATION_TIER1_URL = (id) => {
    return API_URL + '/location-ones/' + id + '/location-twos';
}

export const TIER1_TIER2_URL = (id) => {
    return API_URL + '/location-twos/' + id + '/location-threes';
}

export const TIER2_TIER3_URL = (id) => {
    return API_URL + '/location-threes/' + id + '/location-fours';
}

export const TIER3_TIER4_URL = (id) => {
    return API_URL + '/location-fours/' + id + '/location-fives';
}
export const TIER4_TIER5_URL = (id) => {
    return API_URL + '/location-fives/' + id + '/location-sixes';
}

export const EDIT_GMS_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/ghs-ones/' + id;
            break;
        case 'tier2':

            return API_URL + '/ghs-twos/' + id;
            break;

        default: break;
    }
}

export const EDIT_TIER_URL = (mode, id) => {
    switch (mode) {

        case 'tier1':

            return API_URL + '/location-twos/' + id;
            break;
        case 'tier2':

            return API_URL + '/location-threes/' + id;
            break;
        case 'tier3':

            return API_URL + '/location-fours/' + id;
            break;
        case 'tier4':

            return API_URL + '/location-fives/' + id;
            break;

        case 'tier5':

            return API_URL + '/location-sixes/' + id;
            break;

        default: break;
    }
}

